import Network    from '@/services/network'
import {useQuery} from '@/services/utils/query'

export default function handleWorkflow() {
    const network = new Network;
    const {allQuery} = useQuery()

    const fetchPages = (query) => {
        return network.api('get', 'get/pages'+query);
    }

    const fetchWorkflowList = (query) => {
        return network.api('get', '/workflow'+query);
    }

    const storeWorkflow = (data, query = '') => {
        return network.api('post', '/workflow'+query, data);
    }

    const storeOrUpdateStep = (data, query = '') => {
        return network.api('post', '/workflow/store/step'+query, data);
    }

    const deleteStep = (stepId, query = '') => {
        return network.api('delete', `/workflow/delete/step/${stepId}`+query);
    }

    const deleteCondition = (conditionId, query = '') => {
        return network.api('delete', `/workflow/delete/condition/${conditionId}`+query);
    }

    const fetchWorkflow = (query, workflowId) => {
        return network.api('get', `/workflow/${workflowId}`+query);
    }

    const fetchWorkflowFromPageId = (query) => {
        return network.api('get', `/workflow/from/page`+query);
    }

    const fetchPageIdFromRoute = (route) => {
        return network.api('get', `get/page-id/${route}`);
    }

    const fetchMessageTemplateList = (query) => {
        return network.api('get', '/message-template'+query);
    }
    const storeMessageTemplate = (data, query) => {
        return network.api('post', '/message-template'+query, data);
    }
    const fetchSingleMessageTemplate = (templateId, query) => {
        return network.api('get', '/message-template/' + templateId + query);
    }
    const updateMessageTemplate = (data, templateId, query) => {
        return network.api('put', '/message-template/'+ templateId + query, data);
    }

    return {
        fetchPages,
        fetchWorkflowList,
        storeWorkflow,
        fetchWorkflow,
        storeOrUpdateStep,
        deleteStep,
        deleteCondition,
        fetchPageIdFromRoute,
        fetchWorkflowFromPageId,
        fetchMessageTemplateList,
        storeMessageTemplate,
        fetchSingleMessageTemplate,
        updateMessageTemplate
    }
}